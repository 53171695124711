export const skippableErrorCodes = [
  'NOT_FOUND_IN_ROOT',
  'TOK_P',
  'TOK_M',
  'TOK_I',
  'ACC_E',
  'GKE_I',
  'GKE_A',
  'GKE_B',
  'PAY_W',
  'BIR_N',
  'PROMO_CODE_NOT_APPLICABLE',
  'PROMO_CODE_NOT_FOUND',
  'PROMO_CODE_NOT_ACTIVE',
  'PROMO_CODE_EXHAUSTED',
] as const;

export const skippableErrorPaths = ['$.selectionSet.orders.selectionSet.address'];
