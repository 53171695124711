import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';

import config from 'config';
import { leaselinkTypeMap, leaselinkTypes } from 'consts/leaselink';
import { PaymentId } from 'types/models/PaymentMethod';
import { ShipmentId } from 'types/models/ShipmentMethod';
import { LeaselinkOffer } from 'types/models/LeaselinkOffer';
import { getLeaselinkCartCalculationRoute } from 'apiRoutes/leaselink';
import { FormValues } from '@pages/CheckoutDeliveryPage/CheckoutDeliveryPage.types';
import { UserContext } from '@providers/UserProvider';
import { SnackbarContext, longAlertDisplayTime } from '@providers/SnackbarProvider';
// import { defaultCreditCardValues } from '@components/CreditCard/CreditCard.consts';
// import { FormValues as CreditCardFormValues } from '@components/CreditCard/CreditCard.types';
import { UserTypeId } from '@components/CheckoutListOption';
import { getAddPaymentInfoPayload } from 'utils/analytics/payload/addPaymentInfo';
import { getAddShippingInfoPayload } from 'utils/analytics/payload/addShippingInfo';
import formatPhoneField from 'utils/formatPhoneField';
import useTrack from 'hooks/useTrack';
import useFetch from 'hooks/useFetch';
import useIntl from 'hooks/useIntl';
import useRefBoolean from 'hooks/useRefBoolean';

import { Props, GoToSummary } from './CheckoutProvider.types';
import { CheckoutContext, defaultValues } from './CheckoutProvider.context';
import messages from './CheckoutProvider.messages';

const { routes } = config;

const CheckoutProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const intl = useIntl();
  const { userData } = useContext(UserContext);
  const { showMessage } = useContext(SnackbarContext);
  const { trackLink } = useTrack();
  const { Provider } = CheckoutContext;

  const [getLeaselinkCartCalculation, { data: leaselinkData, loading: leaselinkLoading }] = useFetch(
    getLeaselinkCartCalculationRoute
  );
  const [isLeaselinkLoaded, { on: setLeaselinkDataAsLoaded, off: removeLeaselinkData }] = useRefBoolean(false);
  const [initialValues, setInitialValues] = useState<FormValues>(defaultValues);
  // const [initialCreditCardValues, setInitialCreditCardValues] = useState<CreditCardFormValues>(defaultCreditCardValues);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState<PaymentId>('WIRE_TRANSFER');
  const [activeBankId, setActiveBankId] = useState<number>();
  const [activeLeasingOffer, setActiveLeasingOffer] = useState<LeaselinkOffer>();
  const [activeShippingMethodId, setActiveShippingMethodId] = useState<ShipmentId>('COURIER');
  const [activeUserTypeId, setActiveUserTypeId] = useState<UserTypeId>('COMPANY');
  const [activeCompanyDataId, setActiveCompanyDataId] = useState<number>();
  const [activeShipmentId, setActiveShipmentId] = useState<number>();

  useEffect(() => {
    if (
      leaselinkData &&
      leaselinkTypes.includes(activePaymentMethodId) &&
      activeLeasingOffer?.FinancialProductType !== leaselinkTypeMap[activePaymentMethodId]
    ) {
      setActiveLeasingOffer(
        leaselinkData.Offers.find((offer) => offer.FinancialProductType === leaselinkTypeMap[activePaymentMethodId])
      );
    }
  }, [leaselinkData, activePaymentMethodId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const resetProvider = (currentUrl: string) => {
      const checkoutHrefs = [routes.checkout.delivery.href, routes.checkout.summary.href];
      if (!checkoutHrefs.includes(currentUrl)) {
        setActivePaymentMethodId('WIRE_TRANSFER');
        removeLeaselinkData();
        setInitialValues({ ...defaultValues, termsOfUse: !!userData?.hasSeenLatestTermsOfSale });
        // setInitialCreditCardValues(defaultCreditCardValues);
        setSubmittingOrder(false);
      }
    };

    router.events.on('routeChangeComplete', resetProvider);

    return () => {
      router.events.off('routeChangeComplete', resetProvider);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialValues({ ...defaultValues, termsOfUse: !!userData?.hasSeenLatestTermsOfSale });
  }, [userData?.hasSeenLatestTermsOfSale]);

  const goToSummary: GoToSummary = (values, { totalPrice, products, paymentMethod, shippingMethod }) => {
    setInitialValues({
      ...values,
      phoneNumber: formatPhoneField(values.phoneNumber),
      additionalPhoneNumber: formatPhoneField(values.additionalPhoneNumber),
    });
    trackLink(
      'e-commerce',
      'add_payment_info',
      getAddPaymentInfoPayload(totalPrice, products, paymentMethod?.name)
    )(() => {
      trackLink(
        'e-commerce',
        'add_shipping_info',
        getAddShippingInfoPayload(totalPrice, products, shippingMethod?.name)
      )(() => {
        router.push(routes.checkout.summary.href);
      });
    });
  };

  return (
    <Provider
      value={{
        submittingOrder,
        activeUserTypeId,
        activeShippingMethodId,
        activePaymentMethodId,
        activeBankId,
        activeCompanyDataId,
        activeShipmentId,
        activeLeasingOffer,
        initialValues,
        leasingOffers: leaselinkData?.Offers || [],
        // initialCreditCardValues,
        setInitialValues,
        // setInitialCreditCardValues,
        setActiveLeasingOffer,
        setActiveUserTypeId,
        setActiveShippingMethodId,
        setActivePaymentMethodId: async (newPaymentMethodId: PaymentId) => {
          if (leaselinkTypes.includes(newPaymentMethodId) && !leaselinkLoading && !isLeaselinkLoaded.current) {
            const { data, error } = await getLeaselinkCartCalculation({ multiOffer: true });
            if (data && !error) {
              setLeaselinkDataAsLoaded();
              setActivePaymentMethodId((currentActivePaymentMethodId) =>
                currentActivePaymentMethodId !== activePaymentMethodId
                  ? currentActivePaymentMethodId
                  : newPaymentMethodId
              );
            } else {
              showMessage('error', intl.formatMessage(messages.error), longAlertDisplayTime);
            }
          } else {
            setActivePaymentMethodId(newPaymentMethodId);
          }
        },
        setActiveBankId,
        setActiveCompanyDataId,
        setActiveShipmentId,
        setSubmittingOrder,
        goToSummary,
      }}
    >
      {children}
    </Provider>
  );
};

export default CheckoutProvider;
