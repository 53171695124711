import { MutableRefObject, useRef } from 'react';

type UseRefBooleanReturnType = [
  MutableRefObject<boolean>,
  {
    setValue: (value: boolean) => void;
    toggle: () => void;
    on: () => void;
    off: () => void;
  },
];

const useRefBoolean = (initialValue = false): UseRefBooleanReturnType => {
  const ref = useRef(initialValue);

  const setValue = (value: boolean) => {
    ref.current = value;
  };

  const toggle = () => {
    ref.current = !ref.current;
  };

  const on = () => {
    ref.current = true;
  };

  const off = () => {
    ref.current = false;
  };

  return [ref, { setValue, toggle, on, off }];
};

export default useRefBoolean;
