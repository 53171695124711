import { PaymentId } from 'types/models/PaymentMethod';
import { FinancialProductType } from 'types/models/LeaselinkOffer';

export const leaselinkTypeMap: Partial<Record<PaymentId, FinancialProductType>> = {
  LEASELINK_LEASING: 'OperationalLeasing',
  LEASELINK_LOAN: 'Loan',
  LEASELINK_DEFERRED: 'DeferredPayment',
};

export const leaselinkTypes = Object.keys(leaselinkTypeMap);
