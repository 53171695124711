import { GetLeaselinkCartCalculationRoute } from 'api/leaselink/cart';
import { GetLeaselinkOrderCalculationRoute, CreateLeaselinkRequestRoute } from 'api/leaselink/orders/[id]';
import { GetLeaselinkVariantCalculationRoute } from 'api/leaselink/variant/[id]';
import ApiRoute from 'utils/apiRoute';

export const getLeaselinkVariantCalculationRoute: GetLeaselinkVariantCalculationRoute = new ApiRoute(
  '/leaselink/variant/:id',
  'GET'
);
export const getLeaselinkCartCalculationRoute: GetLeaselinkCartCalculationRoute = new ApiRoute(
  '/leaselink/cart',
  'GET'
);
export const getLeaselinkOrderCalculationRoute: GetLeaselinkOrderCalculationRoute = new ApiRoute(
  '/leaselink/orders/:id',
  'GET'
);
export const createLeaselinkRequestRoute: CreateLeaselinkRequestRoute = new ApiRoute('/leaselink/orders/:id', 'POST');
